import {
    USER_DATA, NOTIFICATIONS,ULTIMA_COMPROBACION
} from "./actions";


const INITIAL_STATE = {
    user: null,
    ultimaComprobacion: null,
    notifications: [] // {tipo: "error, warning, info", message:''}
}

const ReducerUserData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_DATA:
            return {
                ...state,
                user: action.payload
            }
        case NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
        }
        case ULTIMA_COMPROBACION:
            return {
                ...state,
                ultimaComprobacion: action.payload
            }
        default:
            return state;
    }
}


export default ReducerUserData;
