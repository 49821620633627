import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './ReducerAppData';

import ReducerUserData from './ReducerUserData';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    user_data : ReducerUserData
});

export default reducer;
