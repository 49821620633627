// assets
import { IconTrash, IconArrowForwardUp } from '@tabler/icons-react';

// constant
const icons = { IconTrash, IconArrowForwardUp };

// ==============================||  DOCUMENTATION MENU ITEMS ||============================== //

/** Otras Bandejas: Enlaces de las bandejas opcionales disponibles. */
const other = {
    id: 'otrasbandejas',
    title: 'Otras Bandejas',
    type: 'group',
    children: [
        {
            id: 'verreasignados',
            title: 'Expedientes Reasignados y Cedidos',
            subtitle: 'Encuentre aquí documentos y expedientes Reasignados y Cedidos por el usuario. Para ver la trazabilidad, entre al documento y vaya a la pestaña Trazabilidad',
            type: 'item',
            url: '/reasignados',
            icon: icons.IconArrowForwardUp,
            breadcrumbs: true
        },
        {
            id: 'vereliminados',
            title: 'Documentos Eliminados',
            type: 'item',
            url: '/eliminados',
            icon: icons.IconTrash,
            breadcrumbs: true
        }
        
    ]
};

//  target: true  para abrir en nueva pestana

export default other;
