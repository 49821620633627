// material-ui
import { Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import NavGroup from './NavGroup';
import menuItems from 'menu-items';
import { useSelector } from "react-redux";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const theme = useTheme();
    let permisos = useSelector(state => state.user_data.user.permisos) || [];
   
    if(typeof permisos === "string"){
        permisos= permisos.trim().split(',');
    }

    // control de acceso
    const navigation =  menuItems.items.filter((item) => permisos.indexOf(item.id)!== -1 );
    const navItems = navigation.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Error en Items del Menu  
                    </Typography>
                );
        }
    });

    return <Grid sx={{overflowY: 'scroll', height: '70vh', }}><Grid sx={{ ...theme.ui.sidebar}}>{navItems}</Grid></Grid>;
};

export default MenuList;
