// assets
import { IconDashboard , IconSend, IconNotes, IconFileOff, IconFiles, IconFileDatabase, IconLibrary ,IconFileUpload } from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconSend, IconNotes, IconFileOff, IconFiles, IconFileDatabase, IconLibrary, IconFileUpload };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

/** Bandejas: Enlaces de las bandejas disponibles. */
const bandejas = {
    id: 'bandejas',
    title: 'Bandejas',
    type: 'group',
    children: [
        
        {
            id: 'default',
            title: 'Recibidos',
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
        {
            id: 'sent',
            title: 'Enviados',
            type: 'item',
            url: '/enviados',
            icon: icons.IconSend,
            breadcrumbs: true
        },
        {
            id: 'draft',
            title: 'Borradores',
            type: 'item',
            url: '/borradores',
            icon: icons.IconNotes,
            breadcrumbs: true
        },
        {
            id: 'moduloarchivo',
            title: 'Módulo archivistico',
            type: 'collapse',
            icon: icons.IconLibrary,
            children: [
                {
                    id: 'cargarArchivo',
                    title: 'Cargar Archivo / Expediente',
                    type: 'item',
                    url: '/archivo',
                    icon: icons.IconFileUpload,
                    breadcrumbs: true
                },
                {
                    id: 'archivos',
                    title: 'Archivos de Gestión',
                    type: 'item',
                    url: '/archivos-cargados',
                    icon: icons.IconFiles,
                    breadcrumbs: true
                },
                {
                    id: 'borradoresarchivo',
                    title: 'Borradores de Archivos de Gestión',
                    type: 'item',
                    url: '/archivos-borradores',
                    icon: icons.IconFileDatabase,
                    breadcrumbs: true
                },
               
            ]
        },
        
    ]
};

export default bandejas;


/**
 * 
 * ,
        {
            id: 'finalizados',
            title: 'Finalizados',
            type: 'item',
            url: '/finalizados',
            icon: icons.IconFileOff,
            breadcrumbs: false
        },
 */