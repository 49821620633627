/** Mensajes de alerta
 *  @param {string} message
 */
import { forwardRef, useImperativeHandle,useState } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
const Messages = forwardRef((props, ref) => {
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });
    const { vertical, horizontal, open } = state;
    const customization = useSelector((state) => state.customization);
    const message= customization.message;
    const dispatch = useDispatch();

    const handleClose = () => {
        setState({ ...state, open: false });
        dispatch({ type: 'app_messages', message: '' });
    };


    // La instancia del componente será extendida
    // con lo que sea que regreses de la devolución de llamada pasada
    // como segundo argumento
    useImperativeHandle(ref, () => ({
        handleClick(newState) {
            if(newState){
                setState({ ...newState, open: true });
            } else {
                setState({  open: true });
            }
            
        }
    }));

    return (
      message? 
        <Box sx={{ width: 500 }}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={message!==''}
            onClose={handleClose}
            message={message}
            key={vertical + horizontal}
            severity="info"
            sx={{ boxShadow: '0px 3px 5px -1px rgba(70, 111, 236, 0.2),0px 6px 10px 0px rgba(255, 255, 255, 0.14),0px 1px 18px 0px rgba(20, 109, 225, 0.12)', '& div': {backgroundColor: '#1dade5'}}}
            action={<CloseIcon sx={{color: '#fff', fontSize: '18px', cursor: 'pointer'}} onClick={handleClose} />}
          />
        </Box> : null
      );
});

export default Messages;

/* Uso del Component
const Parent = () => {
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const childRef = useRef();

  return (
    <div>
      <Child ref={childRef} />
      <button onClick={() => childRef.current. handleClick()}>Click</button>
    </div>
  );
}; */



