/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeUi(theme) {
    return {
        sidebar: {
            backgroundColor: '#eee',
            borderRadius: '10px',
            padding: '10px'
        }
    };
}
