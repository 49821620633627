import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
//import { useDispatch, useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //



const NavGroup = ({ item }) => {
    const theme = useTheme();
    const [show, setShow] = useState(false);
    
    const handleShow = ()=>{
        setShow(!show);
    }

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    function needsFocus  () {
        const currentIndex = document.location.pathname
            .toString();
            //.split('/');
            //.findIndex((id) => id === item.id);   
        if(item.children){
            item.children?.map((menu) => {
                if(menu.url===currentIndex){
                    setShow(true);
                }
                if(typeof menu.children !=='undefined'){
                    menu.children ?.map((menu2) => {
                        if(menu2.url===currentIndex){
                            setShow(true);
                        }
                    });
                }
            });
        }
    }
    useEffect(() => {
        needsFocus();
    },[])
    
    return (
        <>
            <List
                subheader={
                    item.title && (
                       <div style={{flexDirection: 'row', display: 'flex', borderBottom: 'solid 0.5px', cursor: 'pointer' }} onClick={handleShow} >
                            {
                                show ? <ArrowCircleUpIcon fontSize="small" sx={{ marginBottom: 0 }} /> :
                                <ArrowCircleDownIcon fontSize="small" sx={{ marginBottom: 0 }} /> 
                            }
                            <Typography variant="caption" sx={{ ...theme.typography.menuCaption, marginTop: 0, paddingTop:0 }} display="block" gutterBottom>
                                {item.title} 
                                {item.caption && (
                                    <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                        {item.caption}
                                    </Typography>
                                )}
                            </Typography>
                        </div> 
                    )
                }
            >
                {
                show ?  items : null
                }
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
