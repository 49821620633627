import { Typography, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '20px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function CambiarRol({open, setOpen }) {
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
        <Box sx={{ ...style, width: {xs: '100%', md: '400px', lg: '600px'}, textAlign: "center"}}>
            <CloseIcon sx={{position: 'absolute', top: '10px', right: '10px', cursor: 'pointer'}} onClick={handleClose} />
            <Typography variant="h3">Escoja su rol </Typography>
        </Box>
      </Modal>
    );
}

export default CambiarRol;