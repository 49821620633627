import { Outlet } from 'react-router-dom';
import Messages from 'ui-component/moleculas/Message';
import Header from './Header';
import Footer from './Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const title='Sistema de Gestión Documental ';
const descripcion= 'Autoridad Portuaria Puerto Bolívar';
// project imports
// import Customization from '../Customization';

// ==============================|| Public LAYOUT ||============================== //

const defaultTheme = createTheme();
const PublicLayout = () => (
    <ThemeProvider theme={defaultTheme}>
        {window.location.pathname !== '/administracion'?
       <Header title={title} description={descripcion} /> : null }
        <Outlet />
        <Messages />
        {window.location.pathname !== '/administracion'? 
        <Footer title={title} description={descripcion} /> :null }

</ThemeProvider>
);
// <Customization />
export default PublicLayout;
