import { useRef } from 'react';
// import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {

    Chip,

} from '@mui/material';

// assets
import { IconFilePlus } from '@tabler/icons-react';
import { APP_URL } from '../../../../assets/Constantes';



// ==============================|| NOTIFICATION ||============================== //

const NewOficioButton = () => {
    const theme = useTheme();
    // const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef(null);

    return (
        <>
         <Chip
            sx={{
                height: '40px',
                borderRadius: '15px',
                marginTop: '20px',
                marginLeft: 2,
                marginRight: 2,
                marginBottom: 2,
                transition: 'all .2s ease-in-out',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.light,
                '&[aria-controls="menu-list-grow"], &:hover': {
                    borderColor: theme.palette.primary.main,
                    background: `${theme.palette.primary.main}!important`,
                    color: theme.palette.primary.light,
                    '& svg, & .MuiChip-label': {
                        stroke: theme.palette.primary.light,
                        color: theme.palette.primary.light,
                    }
                },
                '& .MuiChip-label': {
                    lineHeight: 0,
                    color: theme.palette.primary.main,
                    overflow: "inherit"
                }

            }}
            icon={
              <IconFilePlus stroke={1.5} size="1.3rem" />
            }
            label="Crear Documento"
            variant="outlined"
            ref={anchorRef}
            aria-haspopup="true"
            onClick={()=>window.open(APP_URL+'documento','_self')}
            color="primary"
        />
      </>
    );
};

export default NewOficioButton;
