import { useEffect , useState} from 'react';
import { useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import MainLayout from 'layout/MainLayout';

// DAVIDREYES
import { setUserData, setNotifications, setUltimaComprobacion } from '../store/ActionsUserData';
import MyStorage from '../store/MyStorage';

// routes
import { Bandejas, OpcionesUsuario, OtrasBandejas, CorrespondenciaRoutes, AdministracionRoutes, Configuraciones } from './DashboardRoutes';
import WebViewRoutes from './WebViewRoutes'
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

/**
 * Obtener los modulos  que tendrán acceso
 * @param {string} permisos 
 * @returns 
 */
function getModulos(permisos){
  permisos = permisos.trim().split(','); 
  let m=[]; //  modulos
  if(permisos.indexOf('bandejas')>-1){ // 
    m=[...m, ...Bandejas];
  }
  if(permisos.indexOf('otrasbandejas')!==-1){
    m=[...m, ...OtrasBandejas];
  }
  if(permisos.indexOf('tareas')!==-1){
    m=[...m, ...OpcionesUsuario];
  }
  
  if(permisos.indexOf('correspondencia')!==-1){
    m=[...m, ...CorrespondenciaRoutes];
  }
  if(permisos.indexOf('administracion')!==-1){
    m=[...m, ...AdministracionRoutes];
  }
  if(permisos.indexOf('otros')!==-1){
    m=[...m, ...Configuraciones ];
  }
  return m;
}

export default function ThemeRoutes() {
  //const user = useSelector((state) => state.user_data);
  const [connected, setConnected] = useState(false);
  const [modulos, setModulos] = useState([]);

  const dispatch = useDispatch();
  function dummyConection(){
    //let user = {"id":"3","nombre":"DAVID FABRICIO REYES","apellido":null,"nacimiento":"2000-01-01","sexo":"O","email":"devaray@hotmail.com","telefono":"09835543900","ciudad":"1","nombre_ciudad":"Machala","direccion":null,"idSocial":null,"tipoLogin":"E","token":null,"fotoFull":null,"foto":null,"permiso":"1","cedula":null,"ruc":null,"portada":null,"estado":"A"};      
    let user = {"id":"37","nombre":"Joel Nieto","apellido":null,"nacimiento":"2000-01-01","sexo":"O","email":"devaray@hotmail.com","telefono":"09835543900","ciudad":"1","nombre_ciudad":"Machala","direccion":null,"idSocial":null,"tipoLogin":"E","token":null,"fotoFull":null,"foto":null,"permiso":"5", 'permisos': 'bandejas,tareas,otrasbandejas', "cedula":null,"ruc":null,"portada":null,"estado":"A"};      
    setModulos(getModulos(user.permisos));
    dispatch(setUserData(user));
    return  setConnected(true);
  }
  useEffect(() => {
    new MyStorage().getUltimaComprobacion().then(f=> { 
      if(f){
         dispatch(setUltimaComprobacion(f));
      }
    });
    new MyStorage().getNotificacions().then(not=> { 
      if(not){
      
        not = JSON.parse(not);
       
        // user.permiso= '4';
         dispatch(setNotifications(not));
      }
    });

    new MyStorage().getUserInfo().then(user => {
        if (user) {
            user = JSON.parse(user);
           // user.permiso= '4';
            dispatch(setUserData(user));
            // control de acceso
            if(typeof user.permisos === "string"){ //validar si hay modulos
              setModulos(getModulos(user.permisos));
            }
            return setConnected(true);
        } else {
           return false;
           //return dummyConection();
        }


    });    
  }, []);

  const  MainRoutes =  {
    path: '/',
    element: <MainLayout />,
    children: modulos 
  };

  //return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
  return useRoutes(connected? [MainRoutes, WebViewRoutes] : [AuthenticationRoutes] , config.basename);
}
