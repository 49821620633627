// material-ui
// import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/logo_appb_stats.jpg';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = ({size}) => {
    // const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <img src={logo} alt="APPB STATS" width={size || "100px"} />
    );
};

export default Logo;
