// assets
import { IconListDetails, IconListCheck, IconFolders, IconBrandAsana } from '@tabler/icons-react';

// constant
const icons = {
    IconListDetails, IconListCheck, IconFolders, IconBrandAsana
};

// ==============================||  PAGINA DE TAREAS MENU ITEMS ||============================== //

/** Tareas: Enlaces de los enlaces de tareas. */

const tareas = {
    id: 'tareas',
    title: 'Opciones de Usuario',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'tareasrecibidos',
            title: 'Tareas Recibidas',
            type: 'item',
            icon: icons.IconListCheck,
            breadcrumbs: true,
            url: '/tareasrecibidas',
        },
        {
            id: 'tareastodas',
            title: 'Tareas Enviadas',
            type: 'item',
            icon: icons.IconListDetails,
            breadcrumbs: true,
            url: '/tareas',
        },
        {
            id: 'carpetasgestion',
            title: 'Series documentales',
            type: 'item',
            icon: icons.IconFolders,
            breadcrumbs: true,
            url: '/miscarpetas',
        },
        {
            id: 'gestion',
            title: 'Inventario de Transferencia Primaria',
            type: 'item',
            url: '/archivo-gestion',
            icon: icons.IconBrandAsana,
            breadcrumbs: true
        }
    ]
};

export default tareas;
//type: 'collapse',