
import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/atoms/Loadable';

// Oficios

// dashboard routing
const BandejaDeEntrada = Loadable(lazy(() => import('views/BandejaDeEntrada')));

// navigation main
const Carpetas = Loadable(lazy(() => import('views/Carpetas')));
const Documento = Loadable(lazy(() => import('views/Documentos/Documento')));
const Borrador = Loadable(lazy(() => import('views/Documentos/Documento')));
const NuevoDocumento = Loadable(lazy(() => import('views/Documentos/Nuevo')));
const NuevoArchivo = Loadable(lazy(() => import('views/Documentos/NuevoArchivo')));
const Enviados = Loadable(lazy(()=> import('views/Documentos/Enviados')));
const Borradores = Loadable(lazy(()=> import('views/Documentos/Borradores')));
const Archivos = Loadable(lazy(()=> import('views/Archivo/Archivos')));

const InventarioGestion = Loadable(lazy(() => import('views/Archivo/InventarioGestion')));
const InventarioCentral = Loadable(lazy(() => import('views/Archivo/InventarioCentral')));
const BorradoresDeArchivo = Loadable(lazy(()=> import('views/Archivo/BorradoresDeArchivos')));
const Eliminados = Loadable(lazy(()=> import('views/Archivo/Eliminados')));
const Reasignados = Loadable(lazy(()=> import('views/Archivo/Reasignados')));

const Correspondencias = Loadable(lazy(() => import('views/Correspondencia')));
const Correspondencia= Loadable(lazy(() => import('views/Correspondencia/Nuevo')));
const CorresBorradores = Loadable(lazy(() => import('views/Correspondencia/Borradores')));
const CorrespondenciaVer = Loadable(lazy(() => import('views/Correspondencia/Correspondencia')));

const ListadoAprobarAdmin = Loadable(lazy(() => import('views/Archivo/ListadoAprobarAdmin')));

// Diseno
const ListaDisenos = Loadable(lazy(() => import('views/Plantillas')));
const NuevaPlantilla = Loadable(lazy(()=> import('views/Plantillas/NuevaPlantilla')));

// Reportes
const ProbarEmail = Loadable(lazy(() => import('views/Administracion/ProbarEmail')));
//const Administration = Loadable(lazy(() => import('views/Administracion')));
const Reportes = Loadable(lazy(() => import('views/Administracion/Reportes')));
const Auditoria = Loadable(lazy(() => import('views/Administracion/Auditoria')));
const Areas = Loadable(lazy(() => import('views/Administracion/Area')));
const TipoDocumento = Loadable(lazy(() => import('views/Administracion/TipoDocumento')));
const BusquedaAvanzada = Loadable(lazy(() => import('views/Administracion/BusquedaAvanzada')));
const EliminadosTodos = Loadable(lazy(()=> import('views/Administracion/EliminadosTodos')));

// ProfileSection
const Profile = Loadable(lazy(() => import('views/Usuario/Profile')));
const Usuarios = Loadable(lazy(() => import('views/Usuario/Usuarios')));
const Tareas = Loadable(lazy(()=> import('views/Tareas/')));
const TareasRecibidas = Loadable(lazy(()=> import('views/Tareas/Recibidos')));
const MisCarpetas = Loadable(lazy(()=> import('views/Carpetas/MisCarpetas')));
const Institucion= Loadable(lazy(()=> import('views/Administracion/Institucion')));
const Ciudad = Loadable(lazy(() => import('views/Administracion/Ciudad')));
const Unidades = Loadable(lazy(() => import('views/Administracion/Unidades'))); 

const Notificaciones = Loadable(lazy(() => import('views/Usuario/Notificaciones')));

const Firmar = Loadable(lazy(() => import('views/Firmar')));

// ==============================|| MAIN ROUTING ||============================== //

export const Bandejas = [
    {
        path: '/',
        element: <BandejaDeEntrada />
    },
    {
        path: '/enviados',
        element: <Enviados />
    },
    {
        path: '/documento-ver/:id',
        element: <Documento />
    },
    {
        path: '/documento',
        element: <NuevoDocumento />
    },
    {
        path: '/documento/:id',
        element: <NuevoDocumento />
    },
    {
        path: '/firmar/:id',
        element: <Firmar />
    },
    {
        path: '/borradores',
        element: <Borradores />

    },
    {
        path: '/borrador/webview',
        element: <Borrador />

    },
    {
        path: '/borrador/webview/:id',
        element: <Borrador />

    },
    {
        path: '/archivo',
        element: <NuevoArchivo />
    },
    {
        path: '/archivo/:id',
        element: <NuevoArchivo />
    },
    {
        path: '/archivos-cargados',
        element: <Archivos />
    },
    {
        path: '/archivos-borradores',
        element: <BorradoresDeArchivo />
    },
    {
        path: '/notificaciones',
        element: <Notificaciones />
    }
];

export const OpcionesUsuario = [
    {
        path: '/tareas',
        element: <Tareas />
    },
    {
        path: '/tareasrecibidas',
        element: <TareasRecibidas />
    },
    {
        path: '/misCarpetas',
        element: <MisCarpetas />
    },
    {
        path: '/usuario',
        children: [
            {
                path: 'perfil',
                element: <Profile />
            },
        ]
    }
]

export const OtrasBandejas =  [
        
        {
            path: '/reasignados',
            element: <Reasignados />
        },
        {
            path: '/eliminados',
            element: <Eliminados />
        },
     
        {
            path: '/archivo-gestion',
            element: <InventarioGestion />
        }
    ];

    export const CorrespondenciaRoutes = [
            {
            path: '/correspondencia',
            children: [
                {
                    path: 'registrar',
                    element: <Correspondencia />
                },
                {
                    path: 'borrador/:id',
                    element: <Correspondencia />
                },
                {
                    path: 'enviadas',
                    element: <Correspondencias />
                },
                {
                    path: 'borradores',
                    element: <CorresBorradores />
                },
                {
                    path: 'ver/:id',
                    element: <CorrespondenciaVer />
                },
            ]
        } 
    ]

    export const AdministracionRoutes = [
        {
            path: '/administration',
            element: <BandejaDeEntrada />
        },
        {
            path: '/plantillas',
            element: <ListaDisenos />
        },
        {
            path: '/NuevaPlantilla/:id',
            element: <NuevaPlantilla />
        },
        {
            path: '/NuevaPlantilla',
            element: <NuevaPlantilla />
        },
        {
            path: '/reportes',
            element: <Reportes />
        },
        
        {
            path: '/auditoria',
            element: <Auditoria />
        },
        {
            path: '/archivo-central',
            element: <InventarioCentral />
        },
        {
            path: '/aprobar-inventario-central',
            element: <ListadoAprobarAdmin />
        }
    ]
    export const Configuraciones = [
        {
            path: '/areas',
            element: <Areas />
        },
        {
            path: '/busqueda-avanzada',
            element: <BusquedaAvanzada />
        },
        {
            path: '/documentos-eliminados',
            element: <EliminadosTodos />
        },
        {
            path: '/tipo-documento',
            element: <TipoDocumento />
        },
        {
            path: '/carpetas-virtuales',
            element: <Carpetas />
        },
          
        {
            path: '/usuarios/',
            element: <Usuarios />
        },  
        {
            path: '/instituciones/',
            element: <Institucion />
        },
        {
            path: '/ciudades/',
            element: <Ciudad />
        },
        {
            path: '/medida/',
            element: <Unidades />
        },
        {
            path: '/test-email',
            element: <ProbarEmail />
        },
    ]

