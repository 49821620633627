import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/atoms/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing 
const WebView = Loadable(lazy(() => import('../views/Web/WebView')));



// ==============================|| AUTHENTICATION ROUTING ||============================== //
// http://www.xxxxx.gob.ec
const WebViewRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
       {
            path: '/webview/:id',
            element: <WebView />
        },
       
    ]
};

export default WebViewRoutes;
