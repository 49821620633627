// assets
import { IconBrandChrome, IconListCheck,IconFileDatabase,IconFileAnalytics } from '@tabler/icons-react';

// constant
const icons = { IconBrandChrome, IconListCheck, IconFileDatabase,IconFileAnalytics };

// ==============================||  DOCUMENTATION MENU ITEMS ||============================== //

/** Otras Bandejas: Enlaces de las bandejas opcionales disponibles. */
const other = {
    id: 'correspondencia',
    title: 'Correspondencia',
    type: 'group',
    children: [
        {
            id: 'correspondencia-registrar',
            title: 'Registrar',
            type: 'item',
            url: '/correspondencia/registrar',
            icon: icons.IconBrandChrome,
            breadcrumbs: true
        },
        {
            id: 'Correspondencia-listado',
            title: 'Búsqueda',
            type: 'item',
            url: '/correspondencia/enviadas',
            icon: icons.IconListCheck,
            breadcrumbs: true
            //target: true
        },
        {
            id: 'Correspondencia-borradores',
            title: 'Borradores',
            type: 'item',
            url: '/correspondencia/borradores',
            icon: icons.IconFileDatabase,
            breadcrumbs: true
            //target: true
        },
        {
            id: 'report',
            title: 'Reportes',
            type: 'item',
            url: '/reportes',
            icon: icons.IconFileAnalytics,
            breadcrumbs: true
        }
    ]
};

export default other;
