// assets
import { IconBrush, IconLayoutList, IconFileSearch, IconBrandAsana, IconShieldCheck, IconTrash } from '@tabler/icons-react';


// constant
const icons = {
    IconBrush,
    IconLayoutList,
    IconFileSearch,
    IconBrandAsana,
    IconShieldCheck,
    IconTrash
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'administracion',
    title: 'Administrador',
    type: 'group',
    children: [
        {
            id: 'busquedaavanzada',
            title: 'Búsqueda Avanzada',
            type: 'item',
            url: '/busqueda-avanzada',
            icon: icons.IconFileSearch,
            breadcrumbs: true,
            //external: true,
        },
        {
            id: 'todosloseliminados',
            title: 'Eliminados',
            type: 'item',
            url: '/documentos-eliminados',
            icon: icons.IconTrash,
            breadcrumbs: true,
            //external: true,
        },
        {
            id: 'template',
            title: 'Plantillas',
            type: 'item',
            url: '/plantillas',
            icon: icons.IconLayoutList,
            breadcrumbs: true
        },
        {
            id: 'template-design',
            title: 'Nueva Plantilla',
            type: 'item',
            url: '/NuevaPlantilla',
            icon: icons.IconBrush,
            breadcrumbs: true
        },
       
        {
            id: 'central',
            title: 'Archivo Central',
            type: 'item',
            url: '/archivo-central',
            icon: icons.IconBrandAsana,
            breadcrumbs: true
        },
        {
            id: 'transferirgestion',
            title: 'Aprobar Transferencia',
            type: 'item',
            url: '/aprobar-inventario-central',
            icon: icons.IconBrandAsana,

        },
        {
            id: 'util-typography',
            title: 'Auditoria',
            type: 'item',
            url: '/auditoria',
            icon: icons.IconShieldCheck,
            breadcrumbs: true
        },

    ]
};

export default utilities;



/*
{
    id: 'util-typography',
    title: 'Typography',
    type: 'item',
    url: '/utils/util-typography',
    icon: icons.IconTypography,
    breadcrumbs: false
},
{
    id: 'util-color',
    title: 'Color',
    type: 'item',
    url: '/utils/util-color',
    icon: icons.IconPalette,
    breadcrumbs: false
},
{
    id: 'util-shadow',
    title: 'Shadow',
    type: 'item',
    url: '/utils/util-shadow',
    icon: icons.IconShadow,
    breadcrumbs: false
},
{
    id: 'icons',
    title: 'Icons',
    type: 'collapse',
    icon: icons.IconWindmill,
    children: [
        {
            id: 'tabler-icons',
            title: 'Tabler Icons',
            type: 'item',
            url: '/icons/tabler-icons',
            breadcrumbs: false
        },
        {
            id: 'material-icons',
            title: 'Material Icons',
            type: 'item',
            url: '/icons/material-icons',
            breadcrumbs: false
        }
    ]
}
*/
