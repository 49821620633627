import {
    USER_DATA, 
    NOTIFICATIONS,
    ULTIMA_COMPROBACION
} from "./actions";

export const setUserData = (user) => {
    return {
        type: USER_DATA,
        payload: user
    }
}

export const setNotifications = (mensajes) => {
    return {
        type: NOTIFICATIONS,
        payload: mensajes
    }
}

export const setUltimaComprobacion = (fecha) => {
    return {
        type: ULTIMA_COMPROBACION,
        payload: fecha
    }
}