import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/atoms/Loadable';
import PublicLayout from 'layout/PublicLayout';

// login option 3 routing
const Login = Loadable(lazy(() => import('views/Usuario/Login')));
const Recovery = Loadable(lazy(() => import('views/Usuario/Recovery')));
const Web = Loadable(lazy(() => import('views/Web')));
const Publico = Loadable(lazy(() =>import('../views/Web/Publico')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <PublicLayout />,
    children: [
        {
            path: '/',
            element: <Web />
        },
        {
            path: '/administration',
            element: <Login />
        },
        {
            path: '/recover-password',
            element: <Recovery />
        },
        {
            path: '/consulta-documento/:id',
            element: <Publico />
        }
    ]
};

export default AuthenticationRoutes;
