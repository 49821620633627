/** 
 * Menú de la pagina de dashboard
 * Importa todos los apartados del menú en la barra lateral
 * @author David F. Reyes
 */
import bandejas from './bandejas';
import tareas from './usuario';
import administracion from './administracion'; //
import otros from './otros';
import otrasbandejas from './otrasBandejas';
import correspondencia from './correspondencia';

// ==============================|| MENU ITEMS ||============================== //

/** Contenedor del menú del superadministrador. */
export const menuItems = {
    items: [bandejas, tareas,  otrasbandejas, correspondencia, administracion, otros ]
};


// dashboard,  utilities

export default menuItems;

// dashboard, pages, utilities, other
