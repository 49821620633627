import { Outlet } from 'react-router-dom';
import Messages from 'ui-component/moleculas/Message';

// project imports
// import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <Outlet />
        <Messages />
    </>
);
// <Customization />
export default MinimalLayout;
