import {  useSelector } from 'react-redux'; // Provider ,
import Message from 'ui-component/moleculas/Message';
// import reducers from './components/redux/reducers';
// import ReduxThunk from 'redux-thunk';
import './App.css';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';



// ==============================|| APP ||============================== //
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/dashboard/Dashboard.js
// https://github.com/codedthemes/berry-free-react-admin-template

const App = (props) => {
   console.disableYellowBox = true;

   const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>

                    <Routes  />
                   <Message />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
