import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
import { IconLogin2 } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import LogoSection from 'layout/MainLayout/LogoSection';

function Header(props) {
  const { sections, title } = props;

  return (
    <React.Fragment>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <LogoSection />
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1, fontWeight: 'bold', color: '#22afe5'}}
        >
          {title}
        </Typography>
       
        <Button variant="contained" size="small" href='/administration' >
          Entrar <IconLogin2 sx={{ color: '#fff' }} />
        </Button>
      </Toolbar>
     
    </React.Fragment>
  );
}

/** <IconButton>
          <SearchIcon />
        </IconButton> */

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;