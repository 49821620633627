
import axios from 'axios';
import FormData from 'form-data';

// Cuando IS_DEV esta marcado como true el sistema se ejecutara en modo de desarrollo
export const IS_DEV=false ;
//Esta es la librería que se usa para hacer y recibir todas las peticiones  al API
export default class WebApi  {
    //todas las url y direcciones de la API
    SHOW_ALERT= true;
    _APP_URL =  IS_DEV? 'https://sites.xperting.club/appbdoc/' : 'https://gdoc.puertobolivar.gob.ec/'; // gdoc.puertobolivar.gob.ec
    _BASE_URL = IS_DEV? 'https://sites.xperting.club/appbdoc/api/': 'https://gdoc.puertobolivar.gob.ec/api/'; // sites
    _FIRMA_EC_URL = IS_DEV? 'https://impws.firmadigital.gob.ec/': 'https://ws.firmadigital.gob.ec/';
    _SISTEMA_FIRMAEC = IS_DEV ? 'appbPre' : ''; //Api Key Rest: appb-Pre 
    //APPB  931CCFAA17F821DDCFB5A53306748D48A1D5085D3D50EAD5A78924CA7ABA538B
    _IMAGES_URL = this._BASE_URL;
    ANIO_ORIGEN='anioOrigenDocumento.json';
    AUDITORIAS_GET='auditorias.json'; 
    TEMPLATES="templates.json";
    TEMPLATE_NUEVO = "template_nuevo.json";
    TEMPLATE_GET="template_get.json";
    TEMPLATE_DELETE="template_delete.json";
    TEMPLATE_IMAGES='template_images.json';
    TIPOS_ATRIBUTO="tipos_campo.json";
    DOCUMENTO_INVENTARIO= 'documento_inventario.json'; // devuelve todoos los inventarios segun los parametros.
    DOCUMENTO_INVENTARIO_ACCIONES='documento_inventario_acciones.json';
    DOCUMENTO_INVENTARIO_TRANSFERIR='documento_inventario_transferir.json'; // inicia la accion de transferir genera un archivo pdf y firma
    DOCUMENTO_INVENTARIO_TRANSFERIR_2='documento_inventario_transferir_2.json';
    DOCUMENTO_INVENTARIO_TRANSFERIR_3='documento_inventario_transferir_3.json';
    DOCUMENTO_TRANSFERIR='documento_transferir.json'; // el listado de reportes de transferencia mostrados a los gestiores
    DOCUMENTO_TRANSFERIR_DOCUMENTOS='documento_transferir_documentos.json'; // listado de documentos segun los reportes
    DOCUMENTO_TIPO= 'documento_tipo.json';
    DOCUMENTO_NUEVO = 'documento_nuevo.json';
    DOCUMENTO_ENVIADOS = 'documento_enviados.json';
    DOCUMENTO_RECIBIDOS = 'documento_recibidos.json';
    DOCUMENTO_GET = 'documento_get.json';
    DOCUMENTO_GET_PUBLICO='documento_get_publico.json';
    DOCUMENTO_OTRASBANDEJAS = 'documento_otrasbandejas.json';
    DOCUMENTO_ELIMINADOS = 'documento_eliminados.json'; // TODOS LOS ELIMINADOS (ADMIN)
    DOCUMENTO_FINALIZAR = 'documento_finalizar.json';
    DOCUMENTO_ACCIONES= 'documento_acciones.json';
    DOCUMENTO_ANEXO= 'documento_anexo.json';
    DOCUMENTO_ANEXO_ACCIONES= 'documento_anexo_acciones.json';
    DOCUMENTO_ELIMINAR= 'documento_eliminar.json';
    DOCUMENTO_CERRAR= 'documento_cerrar.json';
    DOCUMENTO_REASIGNAR= 'documento_reasignar.json';
    DOCUMENTO_TAREA='documento_tarea.json';
    DOCUMENTO_HISTORIA= 'documento_historial.json';
    DOCUMENTO_RELACIONADOS= 'documento_relacionados.json';
    DOCUMENTO_COMENTARIO= 'documento_comentario.json';
    DOCUMENTO_BUSQUEDA_AVANZADA='documento_busqueda_avanz.json';
    DOCUMENTO_BUSQUEDA_PUBLICA='documento_busqueda_publica.json';
    RESPUESTA='documento_respuesta.json';
    USUARIO='usuario.json';
    USUARIO_GET_PROFILE= 'usuario_perfil.json'; 
    USUARIO_UPDATE= 'usuario_perfil_update.json';
    USUARIO_LOGIN = "usuario_login.json";
    USUARIO_RECOVERY='usuario_recovery.json';
    USUARIO_CIUDADANO='usuario_ciudadano.json';
    USUARIO_DESTINATARIOS = "usuario_destinatarios.json";
    DOCUMENTO_UPLOAD = 'firma_upload.json';
    FIRMA_GET = "firma_get.json";
    DUMMY_URL = "dummy.json";
    REPORTES = 'reportes.json';
    INSTITUCION = 'institucion.json';
    PERMISO='usuario_permiso.json';
  
    TIPIFICACION='tipificacion.json';
    AREAS='area.json';
    SUBAREAS='area_subseccion.json';
    CARPETAS='carpetas.json'; // obtener las carpetas
    CARPETA='carpeta.json'; // todas las acciones de las carpetas 
    CARPETA_DOCUMENTOS='carpeta_documentos.json';
    REQUERIMIENTO_SET='requerimiento_set.json';
    REQUERIMIENTO_BORRAR='requerimiento_borrar.json';
    REQUERIMIENTO_MEDIDAS='requerimiento_medidas.json'; //
    CIUDAD= 'ciudad.json';
    DOCUMENTO_REQUISICION='documento_requisicion.json';

    DOCUMENTO_FIRMA='firmaElectronica.json';
    DOCUMENTO_FIRMA_2='firmaElectronica2.json';
    DOCUMENTO_PREVIEW='firma_documento_preview.json';
    NOTIFICACIONES='user_notificaciones.json';
    ADMINISTRACION='administracion.json';

    FIRMA_EC='firma_ec.json';
    DOCUMENTO_FIRMA_GENERAR='firma_documento_generar.json';

    parseError(error, _url) {
      let err = [];
            err.error = error;
            err.no_result = true;
            IS_DEV && console.log("catch error on ", _url, " call fail", err)
            setTimeout(() => {
                this.SHOW_ALERT &&
                alert("Tenemos problemas para obtener recursos del api "+ _url);
             
            }, 400)
            return err
    }

    notOnline (){
      alert("Al parecer tiene problemas con su conexión a internet");
      let err = [];
      err.error = "Al parecer tiene problemas con su conexión a internet";
      err.no_result = true;
      return err;
    }

    async sendDeleteRequest(_url, _params) {
      if(!navigator.onLine){
        return this.notOnline();
      }
      _url = this._BASE_URL + _url;
      IS_DEV && console.log("API _url", _url);
      try {
          let response = await axios.delete(_url, {
              data: _params ? _params : null,
              timeout: 15000
          });
          IS_DEV && console.log("API call response", response)
          return response;
      } catch (error) {
          return this.parseError(error, _url);
      }
  }

    async sendGetRequest(_url, _params) {
        if(!navigator.onLine){
          return this.notOnline();
        }
        _url = this._BASE_URL + _url;
        IS_DEV && console.log("API _url", _url);
        try {
            let response = await axios.get(_url, {
                data: _params ? _params : null,
                timeout: 15000
            });
            IS_DEV && console.log("API call response", response)
            return response;
        } catch (error) {
            return this.parseError(error, _url);
        }
    }

    async sendPostRequest(_url, _params, headers) {
        if(!navigator.onLine){
          return this.notOnline();
        }
        _url = _url.includes('http')? _url : this._BASE_URL + _url;
        IS_DEV && console.log("API _url", _url);
        if (!_params)  _params = {};
        try {
            let response = await axios({
                method: 'post',
                url: _url,
                data: _params,
                timeout: 15000, 
                headers: headers || { 'x-api-key': process.env.REACT_APP_API_KEY },
                //withCredentials: true 
            } );
            IS_DEV && console.log("API call response", response);
            return response;
        } catch (error) {
            return this.parseError(error, _url);
        }
    }

    async sendPostFileRequest(_url, _params,file) {
      if(!navigator.onLine){
        return this.notOnline();
      }
      _url = this._BASE_URL + _url;
      IS_DEV && console.log("API FILE _url", _url );
      const form_data = new FormData();
      for (const prop in _params){
         form_data.append(`${prop}`, `${_params[prop]}`);
      }
      if(typeof file.type!=="undefined"){ // one file
        // var imageType = /image.*/;  if (!file.type.match(imageType)) return;
        form_data.append('file', file);
      } else {  //varios archivos
        for (let i = 0; i < file.length; i++) {
          form_data.append("file[]", file[i]);
       }
      }
      try {
         let response = await axios({
               method: 'post',
               url: _url,
               data: form_data,
               timeout: 15000,
               headers: {
               'Content-Type': 'multipart/form-data',
               'x-api-key': process.env.REACT_APP_API_KEY
               }
           });
           IS_DEV && console.log("API call response", response);
          return response;
      } catch (error) {
        return this.parseError(error, _url);
      }
    }
  
    firmaGenerarDocumento(id, usuario){
      let url = this.DOCUMENTO_FIRMA_GENERAR + `?id=${id}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }
    // crear documento
    firmarConFirmaEC(id, cedula ){
      let url = this.FIRMA_EC + `?id=${id}&cedula=${cedula}`;
      return this.sendGetRequest(url);
    }
    // fin firmar
    edit(user_id,
      nombre, apellido, email, direccion, cedula, telefono,
            pais, permiso, contrasenia){
        let url= this.DUMMY_URL +
            "?user_id=" + user_id +
            "&nombre=" + nombre +
            "&apellido=" + apellido +
            "&email=" + email +
            "&direccion=" + direccion +
            "&cedula=" + cedula +
            "&telefono=" + telefono +
            "&pais=" + pais +
            "&permiso="+permiso +
            "&contrasenia="+contrasenia
            ;
            return this.sendGetRequest(url)
    }

    getProfile(id){
        let url = this.USUARIO_GET_PROFILE + `?getUsers=true&id=${id}`
        return this.sendGetRequest(url)
    }

    updateProfile(usuario,tipo, valor, contrasena){
          let url = this.USUARIO_UPDATE ;
        if(tipo!=='firma'){
          let params = `getUsers=true&tipo=${tipo}&valor=${valor}&usuario=${usuario}`;
          return this.sendPostRequest(url, params);
        } else {
          let params = {  'tipo': tipo , 'usuario':usuario, 'key': contrasena };
          return this.sendPostFileRequest(url,params, valor );
        }
    }

    getUsuario(id, search, page){
      let url = this.USUARIO + `?get=true&search=${search}&page=${page}`;
      if(id){
        url+=`&id=${id}`;
      }
      return this.sendGetRequest(url);
    }

    deleteUsuario=(id, usuario)=>{
      let url = this.USUARIO + `?delete=true&id=${id}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    contrasenaUsuario=(id, usuario, contrasenia)=>{
      let url = this.USUARIO + `?reset-password=true&id=${id}&usuario=${usuario}&contrasenia=${contrasenia}`;
      return this.sendGetRequest(url);
    }

    resetearContrasena=(email)=>{
      let url = this.USUARIO_RECOVERY ;
      let params= `reset-password=1&email=${email}`;
      return this.sendPostRequest(url, params);
    }
    resetearContrasena2=(email,codigo)=>{
      let url = this.USUARIO_RECOVERY;
      let params=`reset-password=2&email=${email}&codigo=${codigo}`;
      return this.sendPostRequest(url, params);
    }
    resetearContrasena3=(email, codigo,contrasena)=>{
      let url = this.USUARIO_RECOVERY;
      let params=`reset-password=3&email=${email}&codigo=${codigo}&contrasena=${contrasena}`;
      return this.sendPostRequest(url, params);
    }

    desbloquearUsuario=(id, usuario)=>{
      let url = this.USUARIO + `?unlock=true&id=${id}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }
    
    setUsuario(item){
      let permisos=[];
      for(let i=0; i<item.permisos.length; i++){
        permisos.push(item.permisos[i].id)
      }
     
      let url = this.USUARIO 
      let params=`nombre=${item.nombre}&apellido=${item.apellido}&titulo=${item.titulo}&email=${item.email}`;
          params += `&tipoUsuario=${item.tipoUsuario}&telefono=${item.telefono}&permisos=${permisos}&permiso=${item.permiso}`;
          params += `&direccion=${item.direccion}&celular=${item.celular}&cedula=${item.cedula}&cargo=${item.cargo}&area=${item.area}`;
          params += `&idInstitucion=${item.idInstitucion}&referencia=${item.referencia}&idCiudad=${item.idCiudad}&subseccion=${item.subseccion}`;
      if(item.id){
        params+=`&id=${item.id}`;
      }
      return this.sendPostRequest(url, params);
    }

    getPermisos(id){
      let url = this.PERMISO+ `?get=true` ;
      if(id){
        url+=`&id=${id}`;
      }
      return this.sendGetRequest(url);
    }

    userLogin(_usuario, _password, captcha) {
        let url = this.USUARIO_LOGIN ;
        let params = "usuario=" + _usuario + "&contrasena=" + _password +"&g-recaptcha-response="+captcha+ "&getUsers=true";
        return this.sendPostRequest(url,params);
    }

    // mantenimiento usuario ciudadano.
  
    usuarioCiudadano( id, nombre, apellido, direccion, telefono, celular, 
                          email, cedula, institucion, usuario,titulo, cargo,tipoUsuario, referencia, ciudad ){
      let url = this.USUARIO_CIUDADANO;
      let params= `nombre=${nombre}&apellido=${apellido}&direccion=${direccion}&telefono=${telefono}&celular=${celular}`;
          params += `&email=${email}&cedula=${cedula}&institucion=${institucion}&usuario=${usuario}&titulo=${titulo}&cargo=${cargo}&tipoUsuario=${tipoUsuario}`;
          params += `&referencia=${referencia}&ciudad=${ciudad}`;
          if(id) { params+= `&id=${id}`; }
      return this.sendPostRequest(url, params);
    }

    getAuditorias(inicio, fin, pag, search, tabla){
      let url = this.AUDITORIAS_GET + `?pag=${pag}&inicio=${inicio}&fin=${fin}&search=${search}&tabla=${tabla}`;
      return this.sendGetRequest(url)
    }
    setInstituciones(id, nombre, codigo, usuario){
      let url = this.INSTITUCION+ `?create=true&nombre=${nombre}&codigo=${codigo}&usuario=${usuario}` ;
      if(id) { url+= `&id=${id}`; }
      return this.sendGetRequest(url);
    }

    getInstituciones(id){
      let url = this.INSTITUCION ;
      if(id) { url+= `?id=${id}`; }
      return this.sendGetRequest(url);
    }

    deleteInstitucion(id, usuario){
      let url = this.INSTITUCION  + `?delete=true&id=${id}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    setCiudad(id, nombre,  usuario){
      let url = this.CIUDAD;
      let params=`create=true&nombre=${nombre}&usuario=${usuario}` ;
      if(id) { params+= `&id=${id}`; }
      return this.sendPostRequest(url, params);
    }

    getCiudades(id){
      let url = this.CIUDAD + `?get=true`;
      if(id) { url+= `&id=${id}`; }
      return this.sendGetRequest(url);
    }

    deleteCiudad(id, usuario){
      let url = this.CIUDAD  + `?delete=true&id=${id}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    testEmail(usuario, type, email, texto ){
      let url = this.ADMINISTRACION + `?${type}=true&usuario=${usuario}`;
      if(email){
        url= url + `&email=${email}&texto=${texto}`;
      }
      return this.sendGetRequest(url);
    }

   

    

    getTiposDeOficio(search, pag) {
        let url = this.DOCUMENTO_TIPO +`?get=true`;
        if(search){
          url= url +`&search=${search}`;
        } 
        if(pag){
          url= url + `&pag=${pag}`;
        } 
        return this.sendGetRequest(url);
    }

    setTipo(id, nombre, codigo, usuario) {
      let url = this.DOCUMENTO_TIPO ;
      
      let params= `nombre=${nombre}&codigo=${codigo}&usuario=${usuario}`;
      if(id){
        params += `&id=${id}`;
      } 
      return this.sendPostRequest(url, params);
    }
    eliminarTipo(id, usuario){
      let url = this.DOCUMENTO_TIPO +  `?delete=true&id=${id}&usuario=${usuario}`;

        return this.sendGetRequest(url);
    }

    getTipificacion(search, pag) {
      let url = this.TIPIFICACION ;
      if(search){
        url= url `?search=${search}`;
      } 
      if(pag){
        url= url `&pag=${pag}`;
      } 
      return this.sendGetRequest(url);
    }

    getTemplates(search, pag) {
        let url = this.TEMPLATES + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getAllTemplates() {
        let url = this.TEMPLATES + `?all=true`;
        return this.sendGetRequest(url);
    }

    getOneTemplate(id) {
        let url = this.TEMPLATE_GET + `?id=${id}`;
        return this.sendGetRequest(url);
    }

    deleteTemplate(id){
      let url = this.TEMPLATE_DELETE + `?id=${id}`;
       return this.sendGetRequest(url);
    }

    setNewTemplate(id, nombre, descripcion, justificativo, data, usuario, contenido, tipo) {
        let url = this.TEMPLATE_NUEVO ;
        const myDataStr = JSON.stringify(data);
        let params = `id=${id}&nombre=${nombre}&descripcion=${descripcion}`+
                     `&justificativo=${justificativo}&data=${myDataStr}`+
                     `&usuario=${usuario}&contenido=${contenido}&tipo=${tipo}`;
        return this.sendPostRequest(url,params);
    }

    setImagenesTemplate(id, tipo,adjuntos){
      let url= this.TEMPLATE_IMAGES;
      let params ={
        "id" : id, // id plantilla
        "tipo" : tipo // si es pie (de pagina ) o encabezado
      }
      return this.sendPostFileRequest(url,params, adjuntos);
    }

    getDocumentosBusquedaPublica(pag, codigo, cedula )  {
      // codigo es el codigo del documento
      let url = this.DOCUMENTO_BUSQUEDA_PUBLICA + `?codigo=${codigo}&cedula=${cedula}&pag=${pag}`;
      return this.sendGetRequest(url);
    }
    

    getDocumentosBusquedaAvanzada(user_id,pag,search, search2, fecha1, fecha2, categoria, remitente, destinatario )  {
      let url = this.DOCUMENTO_BUSQUEDA_AVANZADA + `?user_id=${user_id}&search=${search}&pag=${pag}`
        + `&search2=${search2}&fecha1=${fecha1}&fecha2=${fecha2}&categoria=${categoria}&remitente=${remitente}&destinatario=${destinatario}`;
      return this.sendGetRequest(url);
    }

    getDocumentosRecibidos(user_id,pag,search, search2, fecha1, fecha2, leido, tipoDeDocumento )  {
      let url = this.DOCUMENTO_RECIBIDOS + `?user_id=${user_id}&search=${search}&pag=${pag}`
        + `&search2=${search2}&fecha1=${fecha1}&fecha2=${fecha2}&leido=${leido}&tipoDeDocumento=${tipoDeDocumento}`;
      return this.sendGetRequest(url);
    }

    getDocumentosArchivo(tab,user_id,pag,search, search2, fecha1, fecha2, leido, tipoDeDocumento, status, area='', subseccion='') {
      let url = this.DOCUMENTO_INVENTARIO + `?tab=${tab}&user_id=${user_id}&search=${search}&pag=${pag}&status=${status}`
      + `&search2=${search2}&fecha1=${fecha1}&fecha2=${fecha2}&leido=${leido}&tipoDeDocumento=${tipoDeDocumento}&area=${area}&subseccion=${subseccion}`;
      return this.sendGetRequest(url);
   }
   getDocumentosTransferir(user_id,pag, status, admin ) {
    let url = this.DOCUMENTO_TRANSFERIR+ `?user_id=${user_id}&pag=${pag}&status=${status}`;
    if(admin){
      url+= `&permiso=${admin}`;
    }
    return this.sendGetRequest(url);
    }
    getDocumentosTransferirDocumentos(ids, status) {
      let url = this.DOCUMENTO_TRANSFERIR_DOCUMENTOS+ `?ids=${ids}&status=${status}`;
      return this.sendGetRequest(url);
      }

    getDocumentosEnviados(user_id,pag,search, search2, fecha1, fecha2, leido, tipoDeDocumento, tipoArchivo) {
        let url = this.DOCUMENTO_ENVIADOS + `?user_id=${user_id}&search=${search}&pag=${pag}&status=A`
        + `&search2=${search2}&fecha1=${fecha1}&fecha2=${fecha2}&leido=${leido}&tipoDeDocumento=${tipoDeDocumento}&tipoArchivo=${tipoArchivo}`;
        return this.sendGetRequest(url);
    }

    getDocumentosBorradores(user_id,pag,search, search2, fecha1, fecha2, leido, tipoDeDocumento, tipoArchivo) {
        let url = this.DOCUMENTO_ENVIADOS 
        + `?user_id=${user_id}&search=${search}&pag=${pag}&status=B`
        + `&search2=${search2}&fecha1=${fecha1}&fecha2=${fecha2}&leido=${leido}&tipoDeDocumento=${tipoDeDocumento}&tipoArchivo=${tipoArchivo}`;
        return this.sendGetRequest(url);
    }

    getDocumentosOtrasBandejas(user_id,pag,search, search2, fecha1, fecha2, leido, tipoDeDocumento, tipoArchivo, reasignados) {
      let url = this.DOCUMENTO_OTRASBANDEJAS 
      + `?user_id=${user_id}&search=${search}&pag=${pag}`
      + `&search2=${search2}&fecha1=${fecha1}&fecha2=${fecha2}&leido=${leido}&tipoDeDocumento=${tipoDeDocumento}&tipoArchivo=${tipoArchivo}`;
      if(reasignados){
        url+=`&reasignados=true`;
      } else {
        url+=`&status=I`;
      }
      return this.sendGetRequest(url);
    }

    getDocumentosTodosEliminados(user_id,pag,search, search2, fecha1, fecha2, leido, tipoDeDocumento) {
      let url = this.DOCUMENTO_ELIMINADOS 
      + `?user_id=${user_id}&search=${search}&pag=${pag}`
      + `&search2=${search2}&fecha1=${fecha1}&fecha2=${fecha2}&leido=${leido}&tipoDeDocumento=${tipoDeDocumento}`;
      
      return this.sendGetRequest(url);
    }

    getDocumento(id, usuario) {
        let url = this.DOCUMENTO_GET + `?id=${id}&usuario=${usuario}`;
        return this.sendGetRequest(url);
    }

    setDocumentoPreview(id, usuario){
      let url = this.DOCUMENTO_PREVIEW + `?id=${id}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    setDocumentoFirma(id, usuario, key){
      let url = this.DOCUMENTO_FIRMA + `?id=${id}&usuario=${usuario}`;
      let params = `key=${key}`;
      return this.sendPostRequest(url, params);
    }

    setDocumentoFirma2(id, usuario, key){
      let url = this.DOCUMENTO_FIRMA_2 + `?id=${id}&usuario=${usuario}&key=${key}`;
      let params = `key=${key}`;
      return this.sendPostRequest(url, params);
    }

    setDocumentoTransferir1(usuario, id, clave ){
     let url= this.DOCUMENTO_INVENTARIO_TRANSFERIR;
      let params= `usuario=${usuario}&id=${id}&key=${clave}`;
      return this.sendPostRequest(url, params);
    }

    setDocumentoTransferir2(usuario, id, clave){
      let url= this.DOCUMENTO_INVENTARIO_TRANSFERIR_2;
      let params= `usuario=${usuario}&id=${id}&key=${clave}`;
      return this.sendPostRequest(url, params);
    }

    setDocumentoTransferir3(usuario, id, clave){
      let url= this.DOCUMENTO_INVENTARIO_TRANSFERIR_3;
      let params= `usuario=${usuario}&id=${id}&key=${clave}`;
      return this.sendPostRequest(url, params);
    }

    getDocumentoPublico(codigo) {
      let url = this.DOCUMENTO_GET_PUBLICO + `?codigo=${codigo}`;
      return this.sendGetRequest(url);
  }

    setRespuesta(idDocumento, asunto, contenido, usuario, adjuntos, claveFirma){
      let url = this.RESPUESTA ;
      let _params = { 'idDocumento':idDocumento, 'asunto': asunto,  'contenido': contenido, 'usuario': usuario, 'key': claveFirma };
      return this.sendPostFileRequest(url,_params, adjuntos);
    }

    documentoEliminar(id, usuario, comentario){
      let url = this.DOCUMENTO_ELIMINAR + `?id=${id}&usuario=${usuario}&comentario=${comentario}` ;
      return this.sendGetRequest(url);
    }

    cerrarExpediente(id, usuario, comentario, seccion, subseccion){
      let url = this.DOCUMENTO_CERRAR + `?cerrar=true&id=${id}&usuario=${usuario}&comentario=${comentario}&seccion=${seccion}&subseccion=${subseccion}` ;
      return this.sendGetRequest(url);
    }

    documentoReasignar(idDocumento, nuevoUsuario,usuario, comment, accion){
      let url = this.DOCUMENTO_REASIGNAR + `?idDocumento=${idDocumento}&nuevoUsuario=${nuevoUsuario}&usuario=${usuario}&comment=${comment}&accion=${accion}` ;
      return this.sendGetRequest(url);
    }

    documentoEditarNombre(id, nombre, usuario){
      let url = this.DOCUMENTO_ACCIONES;
      let params =  `id=${id}&nombre=${nombre}&usuario=${usuario}&edit=true` ;
     return this.sendPostRequest(url, params);
    }

    documentoNuevaTarea(idDocumento, usuarioAsignar,usuario, comentario, fecha){
      let url = this.DOCUMENTO_TAREA;
      let params= `idDocumento=${idDocumento}&usuarioAsignar=${usuarioAsignar}&usuario=${usuario}&comentario=${comentario}&fecha=${fecha}`;
      return this.sendPostRequest(url, params);
    }

    comentarTarea(idTarea, avance, accion,comentario, usuario){ 
      let url = this.DOCUMENTO_TAREA;
          url+= `?comentar=true&idTarea=${idTarea}&avance=${avance}&accion=${accion}&comentario=${comentario}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    comentarDocumento(idDocumento, comentario, usuario){ 
      let url = this.DOCUMENTO_COMENTARIO;
      let param= `?comentar=true&idDocumento=${idDocumento}&comentario=${comentario}&usuario=${usuario}`;
      return this.sendPostRequest(url, param);
    }

    getTareas(pag, usuario, filtro){
      let url = this.DOCUMENTO_TAREA + `?usuario=${usuario}&get=true&pag=${pag}`;
      if(!isNaN(filtro)){ // si es un numero
        url += `&idDocumento=${filtro}`;
      } else if(filtro){
        url += `&tipo=${filtro}`;
      }
      return this.sendGetRequest(url);
    }

    getTareaDocumento( usuario, idDocumento){
      let url = this.DOCUMENTO_TAREA + `?usuario=${usuario}&get=true&tareaDocumento=true&idDocumento=${idDocumento}`;
  
      return this.sendGetRequest(url);
    }

    setTarea(id, comentario, fecha, usuario){
      let url = this.DOCUMENTO_TAREA ;
      let params=  `id=${id}&usuario=${usuario}&fecha=${fecha}&comentario=${comentario}`;
      return this.sendPostRequest(url, params);
    }

    deleteTarea(id, usuario){
      let url = this.DOCUMENTO_TAREA + `?delete=true&id=${id}&usuario=${usuario}` ;
      return this.sendGetRequest(url);
    }

    getHistoria(idDocumento){
      let url = this.DOCUMENTO_HISTORIA + `?idDocumento=${idDocumento}`;
      return this.sendGetRequest(url);
    }

    getRelacionados(idDocumento){
      let url = this.DOCUMENTO_RELACIONADOS + `?documento=${idDocumento}`;
      return this.sendGetRequest(url);
    }

    buscarRelacionados(search, pag=1){
      let url = this.DOCUMENTO_RELACIONADOS + `?search=${search}&pag=${pag}`;
      return this.sendGetRequest(url);
    }

    agregarRelacionado(id, principal, usuario){
      let url = this.DOCUMENTO_RELACIONADOS ;
      let params= `id=${id}&principal=${principal}&usuario=${usuario}`;
      return this.sendPostRequest(url, params);  
    }
    borrarRelacionado(id, principal, usuario, idDocumento){
      let url = this.DOCUMENTO_RELACIONADOS ;
      let params= `id=${id}&principal=${principal}&usuario=${usuario}&idDocumento=${idDocumento}`;
      return this.sendDeleteRequest(url, params);  
    }

    setOficioFinalizar(idOficio,usuario, comment){
      let url= this.DOCUMENTO_FINALIZAR;
      let params = `oficio=${idOficio}&usuario=${usuario}&comentario=${comment}`;
      return this.sendPostRequest(url, params);
    }

    // 
    setDocumentoNuevo(id, asunto, justificativo, content, template, usuario, destinatarios, data, adjuntos, area,
                    tipoDeDocumento, categoria, tipificacion, procedenciaDocumento, referencia, tipoUsuario, 
                    estado,  carpeta,tipoArchivo, fecha, para, comentario,seguridadDocumento='NO', requiereRespuesta='NO', fechaCreacion='' ) {
        //console.log(adjuntos);
        let url = this.DOCUMENTO_NUEVO ;
        
        const myDataStr = JSON.stringify(data);
        const myDestinat = JSON.stringify(destinatarios);
        const destinatario= para.length? para[0].id : []; // destinatario principal
        const p= para.filter((x, index)=> index!==0); // otros destinatarios 
       
        let params ={
          "id" : id,
          "asunto" : asunto,
          "justificativo": justificativo,
          "content": content,
          "data": myDataStr,
          "template": template,
          "usuario": usuario,
          "destinatarios":myDestinat,
          "area": area,
          'tipoDeDocumento': tipoDeDocumento, 
          'categoria': categoria,
          'tipificacion': tipificacion,
          'procedenciaDocumento': procedenciaDocumento,
          'referencia': referencia,
          'tipoUsuario': tipoUsuario,
          'estado': estado,
          'carpeta': carpeta || '',
          'tipoArchivo': tipoArchivo,
          'fecha': fecha,
          'destinatario': destinatario,
          'para': JSON.stringify(p),
          'comentario': comentario,
          'seguridadDocumento': seguridadDocumento, 
          'requiereRespuesta': requiereRespuesta, 'fechaCreacion': fechaCreacion
        }
        return this.sendPostFileRequest(url,params, adjuntos);
    }

    guardarComentarioInventario(id, comentario, status, usuario){
      let url = this.DOCUMENTO_INVENTARIO_ACCIONES;
      let params =  `id=${id}&comentario=${comentario}&usuario=${usuario}&status=${status}` ;
     return this.sendPostRequest(url, params);
    }

    // subir un item para requisicion  
    requerimientoSet(id, documentoId, medida, cantidad, descripcion){
      let url = this.REQUERIMIENTO_SET +`?documentoId=${documentoId}&medida=${medida}&cantidad=${cantidad}&descripcion=${descripcion}&set=true`;
      if(id){
        url = url + `&id=${id}`;
      }
      return this.sendGetRequest(url);
    }

    requerimientoBorrar(id, documentoId, usuario){
      let url = this.REQUERIMIENTO_BORRAR +`?id=${id}&documentoId=${documentoId}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    getMedidas(){
      let url= this.REQUERIMIENTO_MEDIDAS;
      return this.sendGetRequest(url);
    }
    setMedidas(id, nombre, usuario){
      let url= this.REQUERIMIENTO_MEDIDAS;
      let params=  `nombre=${nombre}&usuario=${usuario}`;
      if(id){
        params= params + `&id=${id}`;
      }
      return this.sendPostRequest(url, params);
    }
    medidasBorrar(id, usuario){
      let url = this.REQUERIMIENTO_MEDIDAS +`?id=${id}&usuario=${usuario}&delete=true`;
      return this.sendGetRequest(url);
    }

    getNotificaciones(usuario, permiso) {
      let url = this.NOTIFICACIONES  + `?usuario=${usuario}&permiso=${permiso}`;
     
      return this.sendGetRequest(url);
  }

    getAreas(id) {
        let url = this.AREAS  + `?get=true`;
        if(id){
          url= url + `&id=${id}`;
        }
        return this.sendGetRequest(url);
    }

    setArea(id, nombre, codigo, padre, usuario) {
      let url = this.AREAS  ;
      let params=  `nombre=${nombre}&codigo=${codigo}&padre=${padre}&usuario=${usuario}`;
      if(id){
        params= params + `&id=${id}`;
      }
      return this.sendPostRequest(url, params);
    }

    deleteArea(id, usuario){
      let url = this.AREAS  + `?delete=true&id=${id}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }

    getSubseccion(area, id) {
      let url = this.SUBAREAS  + `?get=true&area=${area}`;
      if(id){
        url= url + `&id=${id}`;
      }
      return this.sendGetRequest(url);
    }

    setSubseccion(id, nombre, area) {
      let url = this.SUBAREAS  ;
      let params=  `nombre=${nombre}&area=${area}`;
      if(id){
        params= params + `&id=${id}`;
      }
      return this.sendPostRequest(url, params);
    }

    deleteSubseccion(id, usuario){
      let url = this.SUBAREAS  + `?delete=true&id=${id}&usuario=${usuario}`;
      return this.sendGetRequest(url);
    }
    
    setCarpetaDocumento(documento, id, usuario){ //id = id de la carpeta
      let url = this.CARPETA  + `?push=true&id=${id}&documento=${documento}&usuario=${usuario}`; ;
      return this.sendGetRequest(url);
    }

    setCarpeta(id, nombreCarpeta, subSeccion, acceso,descripcion, gestion, central, 
      intermedio, historico, baselegal, tecnicaSeleccion, disposicionfinal, carpetaPadre, area, usuario){ //id = id de la carpeta
      let url = this.CARPETA  ;
      let params = `nombreCarpeta=${nombreCarpeta}&subSeccion=${subSeccion}&acceso=${acceso}&descripcion=${descripcion}`;
          params+=  `&gestion=${gestion}&central=${central}&intermedio=${intermedio}&historico=${historico}&baselegal=${baselegal}`;
          params+= `&tecnicaSeleccion=${tecnicaSeleccion}&disposicionfinal=${disposicionfinal}&carpetaPadre=${carpetaPadre}&area=${area}&usuario=${usuario}`;
      if(id){
        params+= `&id=${id}`;
      }
      return this.sendPostRequest(url, params);
    }

    deleteCarpeta(id, usuario){ //id = id de la carpeta
      let url = this.CARPETA + `?id=${id}&usuario=${usuario}&delete=true`; ;

      return this.sendGetRequest(url);
    }

    getCarpetas(area,id, padre, subseccion ) {
      let url = this.CARPETAS  + `?area=${area}` ;
      if(id){
        url= url + `&id=${id}`;
      }
      if(padre){
        url= url + `&padre=${padre}`;
      }
      if(subseccion){
        url+= `&subseccion=${subseccion}`;
      }
      return this.sendGetRequest(url);
    }
    

    getCarpetaDocumentos(carpeta, usuario) {
      let url = this.CARPETA_DOCUMENTOS  + `?carpeta=${carpeta}` ;
      if(usuario){
        url = url + `&usuario=${usuario}`;
      }
      return this.sendGetRequest(url);
    }

    getCarpetasAdmin(area, subseccion) {
      let url = this.CARPETAS  + `?area=${area}&admin=true` ;
      if(subseccion){
        url+= `&subseccion=${subseccion}`;
      }
      return this.sendGetRequest(url);
    }

    getTipos() {
        let url = this.TIPOS_ATRIBUTO ;
        return this.sendGetRequest(url);
    }

    getDestinatarios(search, tipo ) {
        let url = this.USUARIO_DESTINATARIOS + `?search=${search}&getUsers=true&tipoDeUsuario=${tipo}` ;
        return this.sendGetRequest(url);
    }

    getDestinatariosLista(institucion) {
      let url = this.USUARIO_DESTINATARIOS + `?getUsers=true&institucion=${institucion}` ;
      return this.sendGetRequest(url);
    }

    getDestinatariosRequisicion() {
      let url = this.USUARIO_DESTINATARIOS + `?getUsers=true&requisicion=true` ;
      return this.sendGetRequest(url);
    }

    uploadImageCurso(id_curso,file){
      let url = this.DUMMY_URL ;
      let params = 'id='+id_curso+'&eliminar=true';
      if(typeof file === 'undefined' || file.length === 0){
        return this.sendPostRequest(url, params);
      }
      let _params = { 'id':id_curso };
      return this.sendPostFileRequest(url,_params, file[0]);
    }

    borrarAnexos(id,  oficio, usuario ){
      let url = this.DOCUMENTO_ANEXO_ACCIONES;
          url +=  `?id=${id}&oficio=${oficio}&usuario=${usuario}&delete=true` ;
      return this.sendGetRequest(url);
    }

    editarAnexo(id, nombre, usuario){
      let url = this.DOCUMENTO_ANEXO_ACCIONES;
      let params =  `id=${id}&nombre=${nombre}&usuario=${usuario}&edit=true` ;
     return this.sendPostRequest(url, params);
    }
    editarAnexoSerie(id, serie, usuario){
      let url = this.DOCUMENTO_ANEXO_ACCIONES;
      let params =  `id=${id}&serie=${serie}&usuario=${usuario}&edit=true` ;
     return this.sendPostRequest(url, params);
    }
    editarAnexoFecha(id, nombre, usuario){
      let url = this.DOCUMENTO_ANEXO_ACCIONES;
      let params =  `id=${id}&fecha=${nombre}&usuario=${usuario}&edit=true` ;
     return this.sendPostRequest(url, params);
    }


    getDatosRequisicion(area){
      let url = this.DOCUMENTO_REQUISICION+ `?area=${area}`;
      return this.sendGetRequest(url);
    }

    // subir anexos
    uploadAnexos(id, file, oficio, tipo, tipoProcedencia, descripcion, fecha, referencia, usuario){
      let url = this.DOCUMENTO_ANEXO;
      let params = {  'id': id , 'oficio': oficio, 'tipo':tipo, 'tipoProcedencia': tipoProcedencia, 
                      'descripcion': descripcion, 'fecha': fecha ,'referencia': referencia,'usuario': usuario };
      return this.sendPostFileRequest(url,params, file);
    }

    //subir documento firmado electronicamente
    uploadFirma(file, user, oficio, estado='B', nombre){
      let url = this.DOCUMENTO_UPLOAD;
      let params = {'usuario':user, 'oficio': oficio, 'estado':estado};
      if(nombre){
        params['nombre']= nombre;
      }
      return this.sendPostFileRequest(url,params, file);
    }

    getFirma ( documento, user, tipo){
      let url = this.FIRMA_GET + `?usuario=${user}&documento=${documento}&${tipo}=true`;
      return this.sendGetRequest(url);
    }

    getReportes(anio, mes , tipoReporte, pag){
      let url = this.REPORTES + `?pag=${pag}&anio=${anio}&mes=${mes}&tipoReporte=${tipoReporte}`;
      return this.sendGetRequest(url);
    }
    getReportesGlobales(inicio, fin, pag){
      let url = this.REPORTES + `?pag=${pag}&inicio=${inicio}&fin=${fin}`;
      return this.sendGetRequest(url);
    }
// obtener el año de antiguedad de los documentos
    getPrimerAnio(){
      let url = this.ANIO_ORIGEN ;
      return this.sendGetRequest(url);
    }

}
