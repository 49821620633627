import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Messages from 'ui-component/moleculas/Message';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'assets/Constantes';
import { setNotifications, setUltimaComprobacion } from 'store/ActionsUserData';
import {connect } from 'react-redux';
import { SET_MENU, ULTIMA_COMPROBACION } from 'store/actions';
import WebApi from 'store/WebApi';
import MyStorage from 'store/MyStorage';

// assets
import { IconChevronRight } from '@tabler/icons-react';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    // verificar si fueron actualizados
    function updated(){  
        var f1 = new Date (props.ultimaComprobacion);
        var f2 = new Date();
        f1.setHours(0,0,0,0);
        f2.setHours(0,0,0,0);
       return f1.getTime() == f2.getTime();
    }

    const getNotificaciones =()=>{
        
        new WebApi().getNotificaciones(props.user.id, props.user.permiso).then(result => {
          if (result.no_result) {
              return
          }
          let { data } = result;
         
          if (data.not) {
            let notifications= props.notifications?.length ?  [...props.notifications , ... data.not]: data.not;
            props.setNotifications(notifications);         
            new MyStorage().setNotifications(JSON.stringify(notifications));   
            
          } 
            let f = new Date();
            f=f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate()
            props.setUltimaComprobacion(f);
            new MyStorage().setUltimaComprobacion(f); 
          
        })
      }
 
      
    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        let isMounted = true;               // note mutable flag
        
        if (isMounted &&!updated() ) {
             getNotificaciones();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => { isMounted = false }; // cleanup toggles value, if unmounted
    }, [matchDownMd]);
    

    return (
        <Box sx={{ display: 'flex' }}>
            
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>
            
            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            
            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title  rightAlign />
                <Outlet />
                <Messages />
            </Main>

        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user,
        ultimaComprobacion: state.user_data.ultimaComprobacion,
        notifications: state.user_data.notifications
    }
}

const actions = {
   setNotifications, setUltimaComprobacion
}

export default connect(mapStateToProps, actions)(MainLayout);
