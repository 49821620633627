// assets https://tabler-icons.io/
import { IconBrandChrome,  IconFolders, IconBrandAsana, IconUsers, IconBlockquote, IconMapPin, IconBuilding, IconWeight, IconMail } from '@tabler/icons-react';

// constant
const icons = { IconBrandChrome, IconFolders, IconBrandAsana , IconUsers, IconBlockquote, IconMapPin, IconBuilding, IconWeight, IconMail };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
/** Dashbord: Configuraciones de las opciones adicionales.   target: true */
const other = {
    id: 'otros',
    title: 'Configuraciones',
    type: 'group',
    children: [
       
      
        {
            id: 'area',
            title: 'Sección Documental ',
            type: 'item',
            url: '/areas',  
            icon: icons.IconBrandAsana,
        },
        {
            id: 'carpetasvirtuales',
            title: 'Series Documentales',
            type: 'item',
            url: '/carpetas-virtuales',
            icon: icons.IconFolders,
            breadcrumbs: true
            //external: true,
        
        },
        {
            id: 'tipodocumento',
            title: 'Tipos de Documentos ',
            type: 'item',
            url: '/tipo-documento',  
            icon: icons.IconBlockquote,
        },
        {
            id: 'usuarioslistado',
            title: 'Usuarios', 
            type: 'item',
            url: '/usuarios',  
            icon: icons.IconUsers,
        },
        {
            id: 'institucion',
            title: 'Instituciones ',
            type: 'item',
            url: '/instituciones',  
            icon: icons.IconBrandAsana,
        },
        {
            id: 'ciudad',
            title: 'Ciudades ',
            type: 'item',
            url: '/ciudades',  
            icon: icons.IconBuilding,
        },
        {
            id: 'unidades',
            title: 'Unidades de medida ',
            type: 'item',
            url: '/medida',  
            icon: icons.IconWeight,
        },
        {
            id: 'pruebasdeemail',
            title: 'Probar Email ',
            type: 'item',
            url: '/test-email',  
            icon: icons.IconMail,
        },
    ]
};

export default other;


/**
 *  {
            id: 'busqueda-seguimiento',
            title: 'Búsqueda y Seguimiento',
            type: 'item',
            url: '/sample-page',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
 */